::selection {
  background-color: #fbff00;
  color: #2a1809;
}

.App {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  min-height: 100vh;
  overflow-y: auto;
  padding: 0 10px;
}

.App {
  filter: brightness(1.1) contrast(1.2) saturate(1.2) blur(0.5px) sepia(0.2);
  position: relative;
  overflow: hidden;
}

.App > * {
  position: relative;
  z-index: 1;
  filter: drop-shadow(-2px 0 1px rgba(255, 0, 0, 0.7))
    drop-shadow(2px 0 1px rgba(0, 255, 255, 0.7));
}

/* Ajout de l'effet d'écran vintage */
.App::before,
.App::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.App::before {
  z-index: 2;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 255, 0, 0.03) 50%,
    rgba(0, 0, 255, 0.03) 75%,
    rgba(255, 0, 0, 0.03) 75%,
    transparent 100%
  );
  background-size: 100% 4px;
  filter: blur(10000px) grayscale(0.5);
  animation: moveDownSlow 8s linear infinite;
}

.App::after {
  z-index: 3;
  filter: blur(10px) grayscale(0.5);
  background: repeating-linear-gradient(
    0deg,
    rgba(156, 11, 192, 0.084),
    rgba(22, 138, 211, 0.027) 100px,
    transparent 100px,
    transparent 200px
  );
  animation: moveDownSlow 5s linear infinite;
}

body {
  background-color: white;
  min-height: 100vh; /* Assure que le body prend au moins toute la hauteur de la fenêtre */
  color: #2a1809;
}

@keyframes grain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, 5%);
  }
  30% {
    transform: translate(7%, -25%);
  }
  40% {
    transform: translate(-5%, 25%);
  }
  50% {
    transform: translate(-15%, 10%);
  }
  60% {
    transform: translate(15%, 0%);
  }
  70% {
    transform: translate(0%, 15%);
  }
  80% {
    transform: translate(3%, 35%);
  }
  90% {
    transform: translate(-10%, 10%);
  }
}

@keyframes scanlines {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

/* Effet de variation de couleurs */
.App::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 200vh; /* Doublé pour un défilement sans couture */
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(35, 27, 255, 0.03) 50%,
    rgba(138, 202, 40, 0.03) 75%,
    rgba(138, 231, 8, 0.652) 75%,
    transparent 100%
  );
  background-size: 100% 4px;
  z-index: 2;
  pointer-events: none;
  animation: colorShift 800s linear infinite;
}

@keyframes colorShift {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

header {
  flex-shrink: 0;
  /* Add any other header styles */
}

main {
  flex: 1;
  /* Add any other main content styles */
}

footer {
  flex-shrink: 0;
  /* Add any other footer styles */
}

/* Pour Firefox */
.App {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.grain-effect {
  opacity: 0.05;
  animation: grain 800s steps(10) infinite;
}

.scanlines-effect {
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 4px
  );
  animation: scanlines 1200s linear infinite;
}

.color-shift-effect {
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(0, 255, 0, 0.03) 50%,
    rgba(145, 14, 192, 0.038) 75%,
    rgba(255, 0, 0, 0.03) 75%,
    transparent 100%
  );
  background-size: 100% 8px;
  animation: colorShift 800s linear infinite;
}

header,
main,
footer {
  position: relative;
  z-index: 1;
}

/* For Firefox */
.App {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Effet d'écran cathodique */
.cathode-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;
}

/* Effet de lignes cathodiques */
.cathode-lines {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;
}

.scanlines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 4px
  );
  animation: moveDownSlow 100s linear infinite;
}

.color-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 3px,
    rgba(0, 255, 0, 0.02) 3px,
    rgba(0, 255, 0, 0.02) 4px,
    transparent 4px,
    transparent 7px,
    rgba(0, 0, 255, 0.02) 7px,
    rgba(0, 0, 255, 0.02) 8px,
    transparent 8px,
    transparent 11px,
    rgba(255, 0, 0, 0.02) 11px,
    rgba(255, 0, 0, 0.02) 12px
  );
  animation: moveDownSlow 800s linear infinite;
}

@keyframes moveDownSlow {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}

.pixelated-content {
  filter: url(#pagePixelate);
  width: 100%;
  height: 100%;
}
