@font-face {
  font-family: "Departure Mono";
  src: url("../public/fonts/DepartureMono-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPMondwest";
  src: url("../public/fonts/PPMondwest-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PPMondwest";
  src: url("../public/fonts/PPMondwest-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: bold;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a1809;
}

html {
  background-color: #2a1809;
}

.App {
  text-align: left;
}

h1 {
  font-weight: normal;
  font-size: 50px;
  font-family: "PPMondwest";
  image-rendering: pixelated;
  filter: blur(0.5px);
}

p {
  font-family: "Departure Mono";
  font-size: 16px;
}
